import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  SportsEquipmentDto,
  CreateOrUpdateSportsEquipmentDto,
  GetListSportsEquipmentDto
} from '@/framework/models/SportsEquipment'
import {Guid} from 'guid-typescript'

export default class SportsEquipmentApi extends CrudAppService <SportsEquipmentDto, SportsEquipmentDto, Guid,
  GetListSportsEquipmentDto, CreateOrUpdateSportsEquipmentDto, CreateOrUpdateSportsEquipmentDto> {
  constructor() {
    super('/keep-fit/sportsEquipment');
  }

  public async getAllList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/all`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}
