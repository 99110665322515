



















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {
  SportsEquipmentDto,
  CreateOrUpdateSportsEquipmentDto,
  GetListSportsEquipmentDto
} from '@/framework/models/SportsEquipment';
import SportsEquipmentApi from '@/api/gym/SportsEquipment';

@Component({})
export default class SportsEquipment extends Vue {
  private api = new SportsEquipmentApi();
  private listQuery = new GetListSportsEquipmentDto();
  private tableData: PageResultDto<SportsEquipmentDto> = {
    items: new Array<SportsEquipmentDto>(),
    totalCount: 0
  };
  private dialog = {
    title: '',
    visible: false
  }
  private dataForm: CreateOrUpdateSportsEquipmentDto = {
    code: '',
    name: '',
    remark: '',
  }

  private rules = {
    code: [{
      required: true,
      message: '编号不能为空',
      trigger: 'blur'
    }],
    name: [{
      required: true,
      message: '名称不能为空',
      trigger: 'blur'
    }],
  }

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private updateHandle(row: any) {
    this.resetForm();
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dataForm = Object.assign({}, row);
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      code: '',
      name: '',
      remark: '',
    }
  }

  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (this.dialog.title === '新增') {
        await this.creating(this.dataForm);
      } else if (this.dialog.title === '编辑') {
        await this.editing(this.dataForm);
      }
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
  }
}
