import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface SportsEquipmentDto extends ExtensibleEntityDto <Guid> {
  code: string
  name: string
  remark: string
}

export class CreateOrUpdateSportsEquipmentDto {
  public code: string = ''
  public name: string = ''
  public remark: string = ''
}

export class GetListSportsEquipmentDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
